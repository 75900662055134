@import "../../styles/variables.scss";

$container-padding: $spacing-24;

.screenContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: $loginBackground;
}

.formContainer {
  position: fixed;
  padding: $container-padding;
  width: 500px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  overflow: auto;
  background: #fff;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  &>svg {
    transform: scale(1.5);
  }
}

.submit {
  margin-top: $spacing-24;
}

.text {
  text-align: center;
  margin-top: $spacing-24;

  a {
    text-decoration: underline;
  }
}

.title {
  h1 {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
  }
}