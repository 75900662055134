@import "../../../../styles/variables.scss";

.eventWrapper {
    padding: 15px 0 15px 0;
    display: grid;
    grid-template-columns: 100px auto 30px;
    word-break: break-word;
}

.timeContainer {
    line-height: 23px;
}

.descriptionContainer {
    overflow: hidden;
    height: 46px;
    font-size: 15px;
    color: black;
    transition: height 0.25s;
}

.descriptionHtml {}

.descriptionHtml>div>div>div {
    line-height: 23px;
}

.eventDate {
    font-size: 14px;
    color: black;
    font-weight: 500;
}

.eventTitle {
    font-size: 15px;
    color: black;
    font-weight: 700;
    line-height: 25px;
}

.eventTime {
    font-size: 13px;
    color: black;
    font-weight: 500;
}

.chevronContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .chevronIcon {
        transition: transform 0.25s;
        height: 24px;
        width: 24px;

        svg {
            color: $blue-darker;
        }
    }
}