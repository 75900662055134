@import "../../styles/variables.scss";

$container-padding: $spacing-24;

.screenContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: $loginBackground;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loginContainer {
  position: fixed;
  padding: $container-padding;
  width: 500px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  overflow: auto;
  background: #fff;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.viewContainer {
  position: relative;
}

.titleContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;

  img {
    width: 50px;
    margin-right: 5px;
  }

  h1 {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
  }
}

.form {
  margin-top: $spacing-16;
}

.submit {
  margin-top: $spacing-24;
}

.switchViewLabel {
  position: relative;
  text-align: center;
  margin: $container-padding 0 $container-padding 0;
  width: calc(100% + #{$container-padding} + #{$container-padding});
  height: 1px;
  transform: translateX(-#{$container-padding});
  background: $grey-4;

  &>span {
    position: absolute;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    color: $grey-2;
    background: $white;
    padding: $spacing-4 $spacing-12;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
}

.goToView {
  margin-top: $spacing-16;

  span {
    text-decoration: none;
    color: $grey-1;

    &:hover {
      color: #000;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.checkboxContainer {
  margin: $spacing-12 0;

  a {
    color: $blue-original;
    text-decoration: underline;
  }

}

.checkboxContainer>div>input {
  background: tomato;
}

.viewTerms {
  color: $blue-original;
  text-decoration: underline;
  cursor: pointer;
}

.complexLabel {
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
}

@keyframes popIn {
  from {
    transform: translate(-50%, -50%) scale(0.95);
    opacity: 0;
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.errMsg {
  color: red;
  font-size: $font-size-s;
}