@import "../../styles/variables.scss";

// Top navbar

.headerContent {
  background-color: $blue-original;
  z-index: 100;
  position: relative;

  & h1 {
    font-size: $font-size-xxl;
    color: $white;
    font-weight: $font-weight-bold;
  }

  & h2 {
    font-size: $font-weight-semibold;
    color: $white;
    font-weight: $font-weight-medium;
  }
}

.subTitleContainer {
  padding-left: $medium-screen-padding;
}

.headerContent::after {
  background-image: url("../../assets/blue_wave.png");
  background-repeat: repeat-x;
  background-position: bottom;
  content: "";
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  left: 0;
  right: 0;
}

.topnavContainer {
  display: flex;
  justify-content: center;
}

.topnav {
  margin: 1rem;
  background-color: $white;
  width: $width-150;
  height: $default-height - 10;
  border-radius: $radius-10;
}

.headerButtonContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & .actionButton {
    min-width: 220px;
    margin: 0 0.3rem;

    @media (max-width: 457px) {
      margin: 0 0.5rem;
      min-width: unset;
    }

    @media (max-width: 450px) {
      margin: 0 0.5rem;
      min-width: unset;
    }
  }
}

.challengeInfoContainer {
  display: flex;
  justify-content: left;
  margin-top: 1rem;
  gap: 1rem;
  color: $white;
}

.score {
  border: 1px solid $white;
  border-radius: 50px;
  text-align: center;
  padding: 0 10px 0 10px;
}

.challengeRankContainer {
  display: flex;
  justify-content: space-between;
  color: $white;
  padding: 1rem 3rem 0.5rem 0;
}

.challengeRankWrapper {
  display: flex;
  align-content: center;
  align-items: center;

  .rank {
    width: 100px;
  }

  .rankNumber {
    margin-left: 5px;
  }
}

.challengePerformanceWrapper {
  display: flex;
  align-content: center;
  align-items: center;
}

//Media query
@media (max-width: 370px) {
  .headerButtonContainer .actionButton {
    margin: 0 0.2rem 0;
    min-width: unset;
  }
}