@import "../../styles/variables.scss";

.header {
    background-color: $blue-original;
    width: 100%;
    transition: all 300ms ease-in-out;
    height: $main-header-height + 10px;

    @media (max-width: 700px) {
        max-width: 720px;
        margin: 0 auto;
    }

    @media (min-width: 768px) {
        max-width: 720px;
        margin: 0 auto;
    }
}

.container {
    position: relative;
    width: 100%;
    height: $default-height;
}

.headerContent {
    height: $main-header-height;
    display: grid;
    grid-template-columns: 75px auto 75px;
}

.hkLogo {
    display: flex;
    align-items: center;

    img {
        width: 60px;
        margin-left: $medium-screen-padding;
    }
}

.titleAndAdressContainer {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.titleAndAdress {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
        text-align: center;
        font-size: 0.9rem;
        color: white;
    }
}

.titleContainer {
    display: flex;
    align-items: center;

    svg {
        transform: scale(0.7);
        margin-right: $spacing-4;
    }

    p {
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
    }
}

.settings {
    font-size: 27px;
    color: $white;
    display: flex;
    align-items: center;

    a {
        position: absolute;
        top: 18px;
        right: $medium-screen-padding;
        height: 27px;
        width: 25px;
    }
}