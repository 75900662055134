@import "../../styles/variables.scss";

$itemPadding: 6px;

.itemsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: $medium-screen-padding - $itemPadding;

  a:link {
    text-decoration: none;
  }
}

.topNav {
  max-width: $width-150;
  margin: $itemPadding;
  background: $white;
  border-radius: $radius-10;
  padding: 2px;
}

.contentWrapper {
  margin: 0.5rem;

  .iconContainer {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }

  .itemLabel {
    font-size: 12px;
    font-weight: bold;
    line-height: 21px;
  }
}

.blue {
  color: $blue-dark;
}

@media (max-width: $small-screen-width) {
  .itemsContainer {
    display: block;
    width: 100%;
  }

  .topNav {
    max-width: 2000px;
  }

  .contentWrapper {
    display: flex;
    align-items: center;

    .iconContainer {
      margin-bottom: 0;
      margin-right: 7px;
    }
  }
}