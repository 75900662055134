@import "../../../../styles/variables.scss";

.titleContainer {
    display: flex;
    align-items: center;
    display: grid;
    align-items: center;
    grid-template-columns: auto 24px;
    height: 55px;
}

.chevronIcon {
    width: 24px;
    height: 24px;
    transition: transform 250ms;

    svg {
        color: $blue-darker;
    }
}

.content {
    padding-bottom: 20px;
}