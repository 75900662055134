@import "../../styles/variables.scss";

.ranking {
  padding: $spacing-16;
}

.ranks {
  margin-top: $spacing-12;
}

.rank {
  display: flex;
  align-items: center;
  background: $grey-7;
  padding: $spacing-4;
  border-radius: 5px;

  &:nth-child(2n) {
    background: $white;
  }
}

.self {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 0 1px rgba($teal, 0.5);

  .name {
    color: $teal;
  }
}

.name {
  flex: 1 1 auto;
}

.rankNumber,
.points {
  display: flex;
  align-items: center;
}

.rankNumber {
  flex: 0 0 50px;
}
