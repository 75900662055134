@import "../../styles/variables.scss";

.pageContainer,
.transitionContainerInner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
}

.transitionContainer {
  position: relative;
}

.noTabs {
  overflow: auto;
}

.withTabs {
  display: flex;
  flex-direction: column;
}

.tabBar {
  position: relative;
  z-index: 9;
  flex: 0 0 50px;
  border-radius: 15px 15px 0 0;
  transition: all 150ms ease-in-out;

  @media (max-width: 370px) {
    margin-top: 15rem;
  }
}

.tabBarWithMargin {
  padding-top: 35px;
  padding-bottom: 10px;
}

.separator {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.tabPage {
  position: relative;
  flex: 1 1 auto;
  overflow: auto;
}