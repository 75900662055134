@import "../../../styles/variables.scss";

.eventsContainer {
    h1 {
        font-size: 1.3rem;
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }
}

.eventsContainer:not(:last-child) {
    padding-bottom: 20px;
}

.event {
    padding: 25px 0 25px 0;
    border-bottom: 1px solid black;
}

.title {
    font-weight: $font-weight-semibold;
    margin-bottom: 15px;
}

.weekdayTags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    p {
        font-size: 0.9rem;
        color: black;
        background-color: $blue-darker;
        padding: 2px 7px 2px 7px;
        border-radius: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
        color: white;
    }

    p:not(:last-child) {
        margin-right: 7px;
    }
}

.startTimeContainer {
    margin-bottom: 10px;
}

.endTimeContainer {
    margin-bottom: 15px;
}

.timeTitle {
    font-weight: 600;
    margin-bottom: 5px;
}

.startTimeInfo {
    display: flex;
    align-items: center;

    svg {
        margin-right: 10px;
        color: $blue-darker;
    }

    svg:nth-child(3) {
        margin-left: 20px;
    }
}
