@import "../../styles/variables.scss";

$container-padding: $spacing-24;

.submit {
  margin-top: $spacing-24;
}

.text {
  text-align: center;
  margin-top: $spacing-24;
}

.complexLabel {
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
}
