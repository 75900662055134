@import "../../styles/variables.scss";

$container-padding: $spacing-24;

.termsOfService {
  position: fixed;
  width: 460px;
  max-width: calc(100% - 60px);
  max-height: calc(100% - 60px);
  overflow: auto;
  background: #fff;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2),
  0px 3px 5px 2px rgba(0, 0, 0, 0.15);
  animation: popIn 0.2s ease-in-out backwards;
}

.termsOfServiceInner {
  padding: $container-padding;

  & > h1 {
    font-size: $font-size-xl;
    font-weight: $font-weight-medium;
  }

  & > h2 {
    font-size: $font-size-l;
    font-weight: $font-weight-medium;
    margin: 1rem 0 0 0;
  }

  & > h3 {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
  }

  & > p {
    margin: 1rem 0;

    & > ol {
      margin-left: 1rem;
    }

    & > table tr td:first-child {
      padding-right: 1rem;
    }
  }
}

.closeTerms {
  position: sticky;
  top: 0;
  background: $white;
  border-bottom: 1px solid $grey-4;
  padding: $spacing-12 $container-padding;

  & > button {
    font-weight: $font-weight-medium;
    color: black;
    cursor: pointer;
  }
}
