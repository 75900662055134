@import "../../styles/variables.scss";

.sectionHeading {
  font-size: $font-size-l;
  font-weight: $font-weight-bold;
  line-height: 1.2;
}

.white {
  color: $white;
}

.tall {
  padding: $spacing-8 0;
}
