@import "../../styles/variables.scss";

.main {
  position: fixed;
  top: $main-header-height;
  left: 0px;
  right: 0px;
  bottom: $nav-height;
  background: $white;
  border-radius: 15px 15px 0 0;

  @media (min-width: 768px) {
    width: 100%;
    max-width: 720px;
    left: 50%;
    right: auto;
    bottom: $nav-height + 20px;
    transform: translateX(-50%);
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}
