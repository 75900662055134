@import "../../styles/variables.scss";

.alertBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 1px solid #fd4f00;
    border-radius: 10px;
    background: rgba(253, 79, 0, 0.15);
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
    & p {
        width: 350px;
        padding-left: 15px;
    }
}

.alertIcon {
    color: #fd4f00;
    font-size: 1.5rem;
}

.submitButton {
    margin-top: 1rem;
}

.textWidth {
    padding: 0 0 10px;
}

// Trix editor
trix-editor {
    padding: 1.5rem !important;
}
trix-editor figure {
    max-width: 100px;
}

trix-editor figcaption {
    display: none;
}
.editorContainer {
    margin: $spacing-12 0;
    & i {
        color: $grey-3;
        font-size: 10px;
    }
}
trix-editor {
    &::placeholder {
        opacity: 0;
    }

    &:focus {
        outline: none;
        border-color: $teal;
    }
}
progress,
.attachment_progress {
    display: none;
}

[data-trix-button-group="file-tools"] {
    border: none !important;
}
[data-trix-attribute="strike"],
[data-trix-attribute="href"],
[data-trix-attribute="heading1"],
[data-trix-attribute="quote"],
[data-trix-attribute="code"],
[data-trix-attribute="number"],
[data-trix-action="increaseNestingLevel"],
[data-trix-action="decreaseNestingLevel"] {
    display: none;
}

[data-trix-action="attachFiles"] {
    display: none;
}
