@import "../styles/variables.scss";

.opasNewsContainer {
    width: 100%;
    background-color: $blue-darker;
    border-radius: 10px;
    color: $white;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: 40px 0 20px 0;
}

.emptyBox {
    height: 1px;
}