@import "../../../../styles/variables.scss";

.newsItemOpas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.33);
}

.releaseData {
    display: inline-flex;
    gap: 2rem;
    // padding: 0 0 10px 0;
}