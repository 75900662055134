@import "../../styles/variables.scss";

.info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.item {
  display: flex;
  align-items: center;
  padding: $spacing-4 0;
}

.text {
  font-size: 12px;
  white-space: nowrap;
  padding-top: 1px;
}

.divider {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: $black;
  margin: 0 $spacing-8;
}
