@import "../styles/variables.scss";

.eventContainer {
    width: 100%;
    border-radius: 10px;
    color: $white;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 16px;
}

.eventContainer>div:not(:last-child) {
    border-bottom: solid lightgray 1px;
}

.housingCommitteeContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}
