@import "../../styles/variables.scss";

.pollContainer {
  background-color: $blue-darker;
  border-radius: $radius-10;
  width: 100%;
  margin: 1rem 0 1rem;
  border: 5px solid $blue-darker;
}

.pollHeader {
  width: 100%;
  height: auto;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  margin-bottom: 1rem;
  position: relative;
}

.pollHeader::after {
  background-image: url("../../assets/white_wave.png");
  background-repeat: repeat-x;
  background-position: bottom;
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: auto;
  left: 0;
  right: 0;
}


.pollHeaderDate {
  font-size: 14px;
  font-weight: 500;
}

.pollWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem 0 1rem;
  color: $white;
}

.pollTitleWrapper {
  display: grid;
  grid-template-columns: auto 24px;
  align-items: center;
}

.pollTitle {
  font-size: 18px;
  font-weight: bold;
}

.chevronIcon {
  color: white;
  height: 24px;
  width: 24px;
  transition: transform 0.25s;
}

.pollHeaderContent {
  display: flex;
  justify-content: space-between;
  z-index: 1;
  color: $black;
  padding: 1rem;
  font-size: 16px;
  font-weight: bold;
  background: white;
}

//Custom radio button
.customRadio {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0 1rem;
  border-radius: $radius-10 / 2;
  border: 2px solid $white;
  line-height: 24px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.pollBox:not(:last-child),
.answersContainer {
  margin-bottom: 1rem;
}

.myAnswer {
  background: #fff;

  &,
  .filledLabel {
    color: #000;
  }

  .pollBar {
    background: #D6D6D6;
  }
}

.customRadio>input {
  /* HIDE RADIO */
  display: none;
  position: absolute;
  /* Remove input from document flow */
}

.customRadio>input:checked+.customLabel>.filledLabel {
  /* (RADIO CHECKED) DIV STYLES */
  color: $black;
}

.filledLabel {
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: $white;
  z-index: 100;
  padding: 0.75rem 1rem;
  margin: 2px;
}

.pollBar {
  position: absolute;
  top: 2px;
  bottom: 2px;
  margin: 0 3px 0 3px;
  background-color: $blue-darker;
}

.checkMarkIcon {
  font-weight: bold;
}

.pollPercentage {
  z-index: 100;
  padding-right: 0.5rem;
}

.pollForm {
  .formField:not(:first-child) {
    margin-top: 30px;
  }
}

// this empty has to be here or the upper targeting wont work
.formField {}

.pollContentWrapper {
  height: 0px;
  overflow-y: hidden;
}