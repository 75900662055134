@import "../../styles/variables.scss";

.historySelector {
  display: flex;
  margin: $spacing-16;
  margin-bottom: 0;
  border-radius: $spacing-8;
  background: $grey-7;
  border: 1px solid $grey-6;
  position: relative;
}

.container {
  flex: 1 1 auto;
  padding: $spacing-8;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  flex: 0 0 40px;
  display: flex;
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;

  & > svg {
    width: 40px;
    height: 40px;
    color: $grey-2;
  }

  &:focus {
    outline: none;
  }
}

.selection {
  position: relative;
  height: 20px;
}

.label {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.context {
  width: 100%;
  text-align: center;
  margin-bottom: $spacing-4;
}
