// Colours
$black: #000000;
$white: #ffffff;

$grey-1: #37393b;
$grey-2: #5b6060;
$grey-3: #9598a0;
$grey-4: #c0c5d1;
$grey-5: #d9e0e8;
$grey-6: #e5eaf1;
$grey-7: #f7f8fb;
$grey-8: #e5e5e5;
$grey-9: #c4c4c4;
$grey-10: #d6d6d6;
$grey-11: #f2f2f2;
$grey-12: #525252;

$green: #b6d3bb;
$green-light: #def0e1;
$green-dark: #2a5a66;
$green-semi-light: #BAF8EA;

$blue-light: #eff5f8;
$blue-dark: #3f6b88;
$lightblue: #139fcb;

$yellow-light: #fff4d6;

$orange: #e69d28;
$orange-light: #ffe8c4;

$red-light: rgba(253, 79, 0, 0.15);

$loginBackground: #e8f3fcbf;


// Official City of Helsinki colors

$blue: #9FC9EB;
$blue-darker: #0072c6;
$blue-original: #0000bf;

$red: #BD2719;
$red-dark: #FD4F00;

$yellow: #FFE977;
$yellow-dark: #FFC61E;

$green-medium: #009246;
$teal: #00d7a7;


// Spacing
$spacing-2: 2px;
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-48: 48px;
$spacing-64: 64px;
$spacing-128: 128px;

// Typography
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-ml: 18px;
$font-size-xl: 24px;
$font-size-xxl: 32px;

// Dimensions
$main-header-height: 75px;
$default-height: 100px;
$nav-height: 75px;

$width-230: 230px;
$width-150: 150px;

// Curves
$radius-10: 10px;

// Screen width
$small-screen-width: 400px;

// Screen padding
$medium-screen-padding: 16px;
$small-screen-padding: 12px;