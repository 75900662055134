.pageWrapper {
    padding: 50px 16px 16px 16px;
}

.pageHeader {
    position: fixed;
    width: 100%;
    background: white;
    border-radius: 15px 15px 0 0;
    padding: 12px;
    height: 50px;

    svg {
        width: 32px;
    }
}