@import "../../styles/variables.scss";

.container {
  padding: $spacing-16;
}

.medium {
  padding: $spacing-20;
}

.large {
  padding: $spacing-24;
}

.relative {
  position: relative;
}

.grey {
  background: $grey-7;
}

.center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
