@import "../../styles/variables.scss";

.proposalItem {
    border: 1px solid $black;
    border-radius: 10px;
    margin: 1rem 0 1rem;

    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem;
}
.proposalItem::before {
    content: "";
    border: 1px solid $black;
    align-self: stretch;
}
.greyProposalItem {
    border: 1px solid $black;
    border-radius: 10px;
    margin: 1rem 0 1rem;
    background-color: $grey-9;

    display: flex;
    flex-direction: column;
    margin: 1rem 0 1rem;

    &.greyProposalItem::before {
        content: "";
        border: 1px solid $black;
        align-self: stretch;
    }
}

.proposalWrapper {
    flex: 1;
    margin: 1rem;
}

.proposalItemStart {
    order: -1;
}
.proposalActions,
.postHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 10px;

    & h4 {
        font-weight: $font-weight-semibold;
    }
    @media (max-width: 450px) {
        h4 {
            font-size: $font-size-s;
        }
    }
}

.withMargin {
    margin-top: 10px
}

.postResponseHeading {
    display: flex;
    text-align: center;
    align-items: center;
    align-content: center;
    font-size: $font-size-l;

    gap: 10px;
    & p:first-child {
        font-weight: $font-weight-semibold;
    }
    & p:last-child {
        font-weight: $font-weight-medium;
    }

    @media (max-width: 450px) {
        font-size: $font-size-s;
    }
}

.postContent {
    padding: 10px 0 0 0;
}

.postHeading {
    font-size: $font-size-l;
    & p:first-child {
        font-weight: $font-weight-semibold;
    }
    & p:last-child {
        font-weight: $font-weight-medium;
    }
    @media (max-width: 450px) {
        font-size: $font-size-s;
    }
}

.actionContainer {
    padding: 10px 0 10px 0;
    font-size: $font-size-ml;
    font-weight: $font-weight-semibold;
    @media (max-width: 450px) {
        font-size: $font-size-s;
    }
}

.upvoteContainer {
    display: flex;

    p {
        margin: 0;
        padding: 0;
        margin-left: 7px;
        font-weight: $font-weight-medium;
        font-size: $font-size-xl;
    }
}

.numberOfLikes {
    min-width: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
        margin: 0;
        padding: 0;
        font-weight: $font-weight-medium;
        font-size: 20px;
    }
}

.thumbIcon {
    font-size: 1.6rem;
    cursor: pointer;
    margin-right: 5px;
}

.dropIcon {
    font-size: 1.5rem;
    text-align: center;
    padding-top: 4px;
    cursor: pointer;
}

.redDropIcon {
    font-size: 1.5rem;
    text-align: center;
    padding-top: 4px;
    color: $red-dark;
}

.dropDown {
    position: relative;
    display: inline-block;
}

.dropDownBox {
    background: $white;
    position: absolute;
    width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    border-radius: 10px;
    top: 0;
    right: 0;
    margin-top: 1.6rem;
    cursor: pointer;
}

.blueColor {
    color: $blue-darker;
}
.defaultColor {
    color: $black;
}

.flaggeddropDownBox {
    background: $red-light;
    position: absolute;
    width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    border-radius: 10px;
    top: 0;
    right: 0;
    margin-top: 2rem;
}

.tag {
    margin-bottom: 10px;
}
