@import "../../styles/variables.scss";

.searchBox {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: $grey-2;
    background-color: $grey-8;
    border-radius: $radius-10;
    padding: $spacing-12;
    width: 100%;
    margin: 24px 0 20px 0;
}

.searchInput {
    display: flex;
    justify-content: center;
    outline: none;
    text-align: center;
}
