@import "../../styles/variables.scss";

.feed {
  display: flex;
  margin: $spacing-32 0 $spacing-16 0;
  padding: 0 16px;
}

.timeline {
  position: relative;
  flex: 0 0 15px;
  border-left: 2px solid $black;
  margin-bottom: $spacing-16;
  margin-left: $spacing-4;
  transform: translateY(5px);
}

.timelineGradient {
  position: absolute;
  width: 100%;
  height: 60px;
  background: linear-gradient(rgba($white, 0), $white);
  left: -5px;
  bottom: 0;
}

.importantItem {
  position: relative;

  &:after {
    border-radius: 10px;
    content: "";
    position: absolute;
    left: -32px;
    right: -16px;
    top: -16px;
    bottom: -16px;

    border: 1px solid $red-dark;
    pointer-events: none;
    z-index: -1;
  }
}

.importantItem:not(:last-of-type) {
  margin-bottom: 50px;
}

.nonImportantItem {
  margin-bottom: 50px;
}

.items {
  flex: 1 1 auto;
  max-width: 100%;

  & img {
    height: auto;
    max-width: 100%;
  }
}

.top {
  display: grid;
  grid-template-columns: auto 25px;

  .infoContainer {
    display: flex;
    align-items: center;
  }

  .importantContainer {
    color: $red-dark;
    margin-right: 7px;
    padding-top: 1px;
    display: flex;
    align-items: center;
  }
}

.tag {
  margin-right: 7px;
}

.date {
  position: relative;
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  color: $black;
  padding-top: 1px;
}

.checkMark {
  display: flex;
  align-items: center;

  svg {
    font-size: 25px;
    color: $green-medium;
  }
}

.title {
  position: relative;
  font-size: $font-size-l;
  font-weight: $font-weight-medium;
  padding-right: $spacing-16;

  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border: 2px solid $white;
    border-radius: 50%;
    background: $black;
    top: 4px;
    left: -20px;
  }
}

.contentGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(rgba($white, 0), $white);
  opacity: 0;
  transition: opacity 250ms ease-out;

  .collapsed & {
    opacity: 1;
  }
}

.buttons {
  padding-top: $spacing-16;
  display: flex;
  justify-content: flex-end;
}

.toggle {
  display: flex;
  align-items: center;
  color: $teal;
  margin-right: $spacing-4;
  cursor: pointer;
  user-select: none;

  &>svg {
    margin-left: $spacing-4;
    transition: all 0.2s ease-in-out;
  }

  &:focus {
    outline: none;
  }
}

.rotated>svg {
  transform: rotate(180deg);
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}

.spinner {
  max-width: 100px;
}

.textCenter {
  text-align: center;
  margin-top: $spacing-24;
}

.actionContainer {
  display: grid;
  grid-template-columns: auto minmax(auto, max-content);
}

.iconContainer {
  height: 25px;
  min-width: 55px;
  margin-right: 7px;
  border: 1px solid $black;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px 0 5px;

  p {
    font-size: 18px;
  }

  svg {
    font-size: 18px;
    padding-bottom: 2px;
    margin-right: 3px;
    cursor: pointer;
  }
}

.actionIcon {
  display: flex;
  align-content: center;
}

.dropIcon {
  font-size: 25px;
  text-align: center;
  cursor: pointer;
}

.dropDown {
  position: relative;
}

.dropDownBox {
  background: $white;
  position: absolute;
  width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 10px;
  top: 0;
  right: 0;
  margin-top: 2.5rem;

  & p {
    cursor: pointer;
  }

  & p:first-child {
    width: 100%;
    border-bottom: 1px solid $grey-4;
  }
}

.thumbBorder {
  text-align: center;
  width: 60px;
  height: 30px;
  border: 1px solid $black;
  border-radius: 100px;
  border-radius: 100px;
  margin-left: 10px;
}

.liked {
  background-color: $blue;
  border-radius: 100px;
  width: 60px;
  height: 30px;
  text-align: center;
  margin-left: 10px;
}

.thumbIconAnimate {
  animation: anim 0.5s ease-in-out;
  -webkit-animation: anim 0.5s ease-in-out;
}

.blueColor {
  color: $lightblue;
  border: 1px solid $lightblue;
}

.blueDropIcon {
  color: $lightblue;
}

.defaultColor {
  color: $black;
}

.copiedColor {
  color: $lightblue;
}

@keyframes anim {
  100% {
    transform: rotate(-15deg) scale(1);
    -webkit-transform: rotate(-15deg) scale(1);
    -moz-transform: rotate(-15deg) scale(1);
    -ms-transform: rotate(-15deg) scale(1);
    -o-transform: rotate(-15deg) scale(1);
    filter: blur(0.5px);
    -webkit-filter: blur(0.5px);
  }
}

.feedItemFadeOut {
  -webkit-mask-image: linear-gradient(to bottom, black 30%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 30%, transparent 100%);
}