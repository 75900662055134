@import "../../styles/variables.scss";

.achievementDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $spacing-32 0 $spacing-48 0;
}

.name {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: $spacing-8;
  padding: 0 $spacing-8;
}

.tag {
  margin: $spacing-8 $spacing-4 0 $spacing-4;
}
