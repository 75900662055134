@import "../../../styles/variables.scss";

.modalWrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow-y: auto;
}

.modalBox {
  position: relative;
  width: 100%;
  max-width: 450px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;

  & h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    width: auto;
    text-align: left;
    padding: 1rem 0 0 1rem;
  }
}

.modalButton {
  width: 90%;
  margin: 0 auto;
}

.closeButton {
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.proposalModalWrapper {
  margin: 2rem;

  & p:nth-child(2) {
    font-weight: 500;
  }

  & .proposalmodalButton {
    width: 50%;
    margin: 0 auto;
  }
}

// Flag report

.flagModalButton {
  display: flex;
  gap: 1rem;
}

.radioListWrapper {
  text-align: left;
}