@import "../../styles/variables.scss";

$inputColor: $blue-darker;

// Green button
.button {
  width: 100%;
  padding: $spacing-12;
  border-radius: 5px;
  font-weight: $font-weight-medium;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  margin: 1rem 0 1rem;

  &:disabled {
    color: $grey-1;
    background: $grey-5;
    cursor: not-allowed;
  }
}

.outline,
.grey {
  padding: 11px $spacing-12;
  border: 1px solid $teal;
  color: $teal;
  background: $white;

  &:focus {
    background: $grey-7;
  }
}

.grey {
  border-color: $grey-4;
  color: $grey-1;
}

.tealButton {
  background: $teal;
  color: $black;
  &:focus {
    outline: none;
    background: lighten($teal, 5%);
  }
}
.blueButton {
  background: $blue-darker;
  color: $white;
}
.redButton {
  background: $red;
  color: $white;
}
.whiteButton {
  background: $white;
  color: $black;
  &:focus {
    outline: none;
    background: lighten($grey-5, 5%);
  }
}

.darkGreenButton {
  background-color: $green-dark;
  color: $white;
}

.outline,
.grey {
  padding: 11px $spacing-12;
  border: 1px solid $teal;
  color: $teal;
  background: $white;

  &:focus {
    background: $grey-7;
  }
}

.grey {
  border-color: $grey-4;
  color: $grey-1;
}

.inputContainer {
  position: relative;
}

.hiddenInput {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  pointer-events: none;
  top: 32px;
  left: 12px;
}

.radio,
.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding-left: $spacing-32;

  & span {
    transition: all 0.2s ease-in-out;
    user-select: none;
  }

  .hiddenInput:checked ~ & span {
    color: $blue-darker;
  }
}

.radio::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 2px solid #000;

  .hiddenInput:checked ~ & {
    border-color: $blue-darker;
  }
}

.radio::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background: $teal;
  opacity: 0;
  transform: translateY(-50%) scale(0.5);

  .hiddenInput:checked ~ & {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}

.checkbox {
  .icon {
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;

    & svg {
      color: #fff;
    }
  }
}

.checkbox::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: #fff;
  border: 2px solid #000;
  border-radius: 2px;

  .hiddenInput:checked ~ & {
    border-color: $blue-darker;
  }

  .hiddenInput:focus ~ & {
    border-color: lighten($blue-darker, 10%);
  }
}

.checkbox::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: $blue-darker;
  border-radius: 2px;
  opacity: 0;
  transform: translateY(-50%) scale(0.5);

  .hiddenInput:checked ~ & {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
}

.greenBackground::before {
  background: $green-light;
}

.greenBackground.checkbox {
  & svg {
    color: $green-light;
  }
}

.textInputContainer,
.textAreaContainer {
  position: relative;
  padding: 12px 0 5px 0;
  margin-top: 10px;
}

.textBoxContainer {
  position: relative;
  margin-top: $spacing-12;
}

.textAreaBox {
  display: block;
  width: 100%;
  height: 100px;
  padding: $spacing-8;
  border: 2px solid $grey-2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  resize: none;

  &::placeholder {
    opacity: 0;
  }

  &:focus {
    outline: none;
    border-color: $blue-darker;
  }

}

.textAreaBoxWhite {
  display: block;
  width: 100%;
  height: 100px;
  padding: $spacing-8;
  border: 2px solid $white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  resize: none;
  margin-top: 1rem;

  &::placeholder {
    opacity: 0.5;
    color: white;
  }

  &:focus {
    outline: none;
    border-color: $blue-darker;
  }
}

.textInput {
  display: block;
  width: 100%;
  padding: $spacing-8 0;
  border-bottom: 2px solid $black;

  &::placeholder {
    opacity: 0;
  }

  &:focus {
    outline: none;
    border-color: $inputColor;
  }
}

.textInputLabel {
  position: absolute;
  top: 0;
  left: 0px;
  transform: translateY(0);
  font-size: 12px;
  font-weight: $font-weight-medium;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  color: $inputColor;

  .textInput:placeholder-shown ~ & {
    font-size: $font-size-m;
    top: 50%;
    transform: translateY(-50%);
    color: $black;
  }
}

// Text input box
.textInputBoxContainer {
  position: relative;
  padding: $spacing-12 0;
}

.textInputBox {
  display: block;
  width: 100%;
  padding: $spacing-8;
  border: 1px solid $black;
  border-radius: 10px;

  &::placeholder {
    opacity: 0;
  }

  &:focus {
    outline: none;
    border-color: $teal;
  }
}

.textInputBoxLabel {
  position: absolute;
  top: 0;
  left: 0px;
  transform: translateY(0);
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  color: $teal;

  .textInputBox:placeholder-shown ~ & {
    font-size: $font-size-m;
    top: 50%;
    transform: translateY(-50%);
    color: $black;
  }
}
// Text input box ends

.textArea {
  display: block;
  width: 100%;
  height: 100px;
  padding: $spacing-8;
  border: 2px solid $grey-2;
  border-radius: 2px;
  resize: none;

  &::placeholder {
    opacity: 0;
  }

  &:focus {
    outline: none;
    border-color: $teal;
  }
}

.textAreaLabel {
  position: absolute;
  top: -3px;
  left: 0px;
  font-size: $font-size-xs;
  font-weight: $font-weight-medium;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
  color: $teal;

  .textArea:placeholder-shown ~ & {
    font-size: $font-size-m;
    top: 22px;
    left: 10px;
    color: $grey-2;
  }
}

.errorMessage {
  padding-bottom: 0.25rem;

  span {
    font-size: 13px;
    color: red;
  }
}

.showPlaceholder {
  &::placeholder {
    opacity: 1;
    color: $grey-2;
  }
}

//Custom radio button
.customRadio {
  display: block;
  width: 100%;
  padding: 0.1rem;
  margin: 1rem 0 1rem;
  border-radius: $radius-10 / 2;
  border: 2px solid $white;
  line-height: 2rem;
  font-weight: bold;
}

.customRadio > input {
  /* HIDE RADIO */
  visibility: hidden; /* Makes input not-clickable */
  position: absolute; /* Remove input from document flow */
}

.customRadio > input:checked {
  /* (RADIO CHECKED) DIV STYLES */
  background-color: $grey-10;
  color: $black;
  // background-color: #0072c6;
  // padding: 10px;
  border-radius: 2px;
}
.customRadio > input + div {
  /* DIV STYLES */
  cursor: pointer;
  border: 2px solid transparent;
}
// .customRadio > input:checked + .customLabel > .filledLabel {
//   /* (RADIO CHECKED) DIV STYLES */
//   background-color: $grey-10;
//   color: $black;
//   width: 50%;
//   border-radius: 2px;
// }
// .customLabel {
//   background-color: #0072c6;
// }

// .customRadio > input:checked + .customLabel {
//   /* (RADIO CHECKED) DIV STYLES */
//   background-color: $grey-10;
//   color: $black;
//   // background-color: #0072c6;
//   width: 50%;
//   padding: 5px;
//   border-radius: 2px;
// }

.customLabel {
  display: flex;
  justify-content: space-between;
}
