.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: 1em;
}

.containerInitialLineHeight {
  line-height: initial;
}

.centerer {
  display: flex;
  align-items: center;
}

.spinner {
  border: 0.12em solid;
  border-radius: 50%;
  height: 1em;
  width: 1em;
  border-left-color: transparent;
  margin-right: 0.333em;
  will-change: transform;
  animation: spin 1236ms linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
