@import "../../styles/variables.scss";

.filterItemsContainer {
    font-weight: $font-weight-medium;
    padding-bottom: 10px;

    h5 {
        margin-top: 5px;
        margin-bottom: 10px;
    }
}

.checkBoxContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 7px;

    div {
        display: flex;
        align-items: center;
    }

    input {
        margin-right: 7px;
    }

    @media (max-width: $small-screen-width) {
        grid-template-columns: auto auto;
    }
}

.importantContainer {
    color: $red-dark;
    background: $red-light;
    display: flex;
    align-items: center;
    width: 90px;
    border-radius: 15px;
    font-weight: bold;
    text-align: center;

    svg {
        margin-right: 2px;
    }

    padding: 4px;
}