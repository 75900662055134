@import "../../../styles/variables.scss";

.chevronDrop {
    font-size: 1.8rem;
}

.formButtons {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $black;
    // margin: 1rem 0 1rem;
}

.clickable {
    position: relative;
    // z-index: 2;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        // z-index: -1;
        top: -8px;
        right: -8px;
        bottom: -8px;
        left: -8px;
        border-radius: 5px;
        transition: all 200ms ease-in-out;
    }
}

.openIndicator {
    margin-right: $spacing-4;
    width: 24px;
    height: 24px;
    transition: all 200ms ease-in-out;
}

.open {
    .openIndicator {
        transform: rotate(180deg);
    }

    .formButtons {
        border-bottom: none;
    }

    .formButtons {
        border-top: 1px solid $black;
    }
    .top {
        border-bottom: 1px solid $black;
        margin: 1rem 0 1rem;
        background-color: $grey-11;
        padding: 0 1rem 1rem;

        // these are the rendered single events
        li:not(:first-child) {
            margin-top: 30px;
        }
    }
    .collapseContent {
        transition: all 200ms ease-in-out;
    }
}

.itemContainer {
    display: flex;
    padding: 1rem 0 1rem;
}

.suggestionList {
    list-style-type: none;
}

.heading {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 1rem;
}

.date {
    margin-bottom: 1rem;
}
